.page {
  padding: 20px;
  display: block;
  overflow-y: auto;
  height: calc(100vh - 65px);
  width: 100%;

  &__panel {
    height: calc(100vh - 119px);
  }

  &__wrapper {
    display: flex;
  }

  &__sidebar {
    padding: 20px;
    background-color: $white;
    box-shadow: 0 0 30px $light-shadow;
    border-radius: 6px;
    width: 30%;
    max-width: 300px;
    margin-right: 26px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 900px) {
      margin: 0 0 30px;
      width: 100%;
      max-width: 100%;
      .body {
        max-height: 350px;
      }
    }

  }

  &__content {
    width: 100%;
    padding: 12px 20px 20px 20px;
    background-color: $white;
    box-shadow: 0 0 30px $light-shadow;
    border-radius: 6px;
  }

  &__table {
    padding: 12px;
    background-color: $white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__submenu {
    margin: 10px;
  }
}

@media all and (max-width: 900px) {
  .page {
    padding: 10px;

    &__wrapper {
      flex-direction: column;
    }

    &__table {
      min-height: calc(100vh - 140px);

      .search-panel {
        max-width: 100%;
      }
    }
  }
}
