.group {
  width: 100%;

  &__header {
    margin-bottom: 12px;
    font-size: 16px;
    font-family: 'Medium Poppins', sans-serif;
  }

  &__content {

  }

  &-form {
    &__control {
      align-items: flex-start;
      display: flex;
      margin-bottom: 12px;
    }

    &__label {
      width: 100%;
      max-width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
    }

    &__input {
      width: 100%;
    }
  }


  &__footer {
    display: flex;
    justify-content: right;
  }

  &:not(:last-child) {
    margin-bottom: 46px;
  }
}

@media all and (max-width: 900px) {
  .group-form__control {
    flex-direction: column;
  }
  .group-form__label {
    max-width: 100%;
  }
}
