.gm-style {
  // ******* Window Marker Info close button *******
  .gm-ui-hover-effect {
    top: 10px !important;
    right: 10px !important;
    width: 20px !important;
    height: 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    span {
      margin: 0 !important;
      width: 14px !important;
      height: 14px !important;
    }
  }
}

