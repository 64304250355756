@import "variables";
@import "colors";

.notification-budget {
  background: $hot-warning;
  border-radius: 4px;
  color: $white;
  font-size: 10px;
  line-height: 14px;
  padding: 0 3px;
}
