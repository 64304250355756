.button {
  &-delete {
    padding: 4px 16px;
    color: $hot-warning;
    background-color: $hot-warning-hover;
    transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $hot-warning;
      color: white;
    }

    &:disabled {
      color: $disable-button-text;
      background-color: $disable-button;
    }
  }
}

.mat-button-toggle-group {
  &.time-type {
    width: 115px;
  }

  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      line-height: 42px;
    }
  }
}


