@import "styles/colors";

.mat-slide-toggle {
  flex-shrink: 0;

  &.mat-primary {
    &.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
      background-color: $checkbox-bg;
    }

    &.mat-checked .mat-slide-toggle-thumb {
      background-color: $white;
    }
  }
}
