@mixin icon-disabled() {
  filter: invert(64%) sepia(27%) saturate(10%) hue-rotate(0deg) brightness(131%) contrast(94%);
}
@mixin icon-dark-blue() {
  filter: brightness(0) saturate(100%) invert(31%) sepia(63%) saturate(4439%) hue-rotate(202deg) brightness(106%) contrast(103%);
}
@mixin icon-blue() {
  filter: invert(0.5) sepia(1) saturate(30) hue-rotate(180deg) brightness(93%) contrast(90%);
}
@mixin icon-green() {
  filter: invert(56%) sepia(82%) saturate(376%) hue-rotate(125deg) brightness(83%) contrast(90%);
}
@mixin icon-brown() {
  filter: invert(46%) sepia(48%) saturate(3120%) hue-rotate(12deg) brightness(105%) contrast(106%);
}
@mixin icon-gray() {
  filter: invert(75%) sepia(10%) saturate(274%) hue-rotate(201deg) brightness(101%) contrast(80%);
}
@mixin icon-red() {
  filter: invert(39%) sepia(54%) saturate(1771%) hue-rotate(323deg) brightness(99%) contrast(95%);
}
@mixin icon-orange() {
  filter: invert(72%) sepia(57%) saturate(667%) hue-rotate(327deg) brightness(101%) contrast(93%);
}
@mixin icon-white() {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
}
