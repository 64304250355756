@import "variables";
@import "colors";


@mixin themify($themes: $site-themes) {
  @each $theme-name in (dark, light) {

    .theme-#{$theme-name} & {
      @each $key, $variants in $themes {
        $theme-map: () !global;
        $value: map-get($variants, $theme-name);
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
        @content;
        $theme-map: null !global;
      }
    }
  }
}
@function themed($key) {
  @if ($theme-map) {
    @return map-get($theme-map, $key);
  }
  @return null;

}

@each $step, $size in $steps {
  .pl#{$step} { padding-left: $size; }
  .pr#{$step} { padding-right: $size; }
  .pt#{$step} { padding-top: $size; }
  .pb#{$step} { padding-bottom: $size; }
  .p#{$step} { padding: $size; }

  .ml#{$step} { margin-left: $size; }
  .mr#{$step} { margin-right: $size; }
  .mt#{$step} { margin-top: $size; }
  .mb#{$step} { margin-bottom: $size; }
  .m#{$step} { margin: $size; }
}
