$height-errors: 18px;

.popup {
  color: $row-text-grey;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $row-border-grey;
    padding: 3px 16px;
  }
  &__title {
    font: $title-font;
    margin: 0;
  }
  &__label {
    text-align: right;
  }
  &__field {
    align-items: center;
  }
  &__file-upload {
    .drag-and-drop-file-uploading {
      max-width: 100% !important;
    }
  }
  &__form {
    padding: 0 36px;
    margin: 22px 0;
    &.disabled {
      pointer-events: none;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    border-top: 1px solid $row-border-grey;
    padding: 12px 16px;
    &.multiple-buttons {
      justify-content: space-between;
    }
  }
  &__buttons-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__errors {
    min-height: $height-errors;
    .errors-wrapper .error-message {
      margin: 0 !important;
    }
  }
  .fields-input {
    &__action {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: $height-errors;
      > * {
        width: 100%;
      }
      + .popup__errors {
        margin-top: -$height-errors;
      }
    }
    &__label {
      margin-bottom: 4px;
      padding: 0;
      text-align: left;
      font-size: 12px;
    }
    > * {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .field-textarea {
    .fields-input__action {
      align-items: center;
    }
  }

  &.two-columns {
    .popup__form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .fields-input {
        width: 90%;
        &__action {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          > label {
            width: auto;
            margin-right: 25px;
          }
          > *:not(label) {
            width: 250px;
          }
        }
        .app-date-range .mat-form-field {
          height: 44px;
        }
        .app-date-range .mat-form-field-appearance-fill .mat-form-field-flex {
          height: 42px;
        }
      }
    }
    .fields-input > * {
      width: 100%;
    }
    .popup__buttons-container {
      width: 100% !important;
      justify-content: center;
      button {
        margin: 0 22px;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .popup {
    &__label {
      text-align: left;
    }
  }
}

@media all and (max-width: 768px) {
  .popup {
    .fields-input {
      > * {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 460px) {
  .popup {
    &__form {
      padding: 0 12px;
    }
  }
}

