@import "styles/variables";
@import "styles/colors";
@import "styles/multi-themes";

$maxOptionsPanelHeight: 296px;

@mixin field-default {
  width: 100%;
  display: block;
  position: relative;
}

@mixin field-sizing {
  @each $key, $size in $heights {
    &.#{$key} {
      .field-text {
        input:not(.hidden-input),
        select,
        .value {
          height: $size;
          min-height: $size;
          font-size: 14px;
        }
        // Date-picker icon
        .icon-clickable {
          width: $size;
          height: $size - 3px;
        }
      }
    }
  }
}

@mixin field-disabling {
  &.disabled {
    pointer-events: none;

    fieldset.field-label {
      background-color: $disabled-bg;
      border-color: $disabled-border;
      color: $storm-sky;
    }
  }
}

@mixin field-colors {
  .field-label {
    background-color: $row-bg-hover;
    border-color: $row-bg-hover;

    .label {
      color: $header-grey;
    }
  }
  .field-text {
    input,
    .value {
      color: $row-text-grey;
    }
  }

  &.active {
    .field-label {
      border-color: $bright-blue !important;

      .label {
        @include themify() {
          color: themed('el-lbl-active');
        }
      }
    }
  }
  &:not(.active):hover {
    .field-label {
      border-color: $slate-ten !important;
    }
  }
}

@mixin field-invalid {
  &.invalid {
    &:not(.active) fieldset.field-label {
      margin-bottom: 20px;
      border-color: $hot-steal !important;
    }
  }

  * {
    transition: all 0.2s ease;
  }
}

@mixin field-label-style {
  fieldset.field-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 6px;
    pointer-events: none;
    transition: all 0.2s ease;

    &.label-hidden {
      legend,
      .label {
        display: none;
      }
    }
    .label {
      font-size: 14px;
      z-index: 50;
      line-height: 14px;
      margin: 0 10px;
      white-space: nowrap;
      position: absolute;
      top: calc(50% - 6px);
      left: 0;
      max-width: calc(100% - 14px);
      overflow: hidden;
    }
    legend {
      width: 0;
      height: 2px;
      transition: all 0.2s ease;
      max-width: calc(100% - 10px);
      margin: 0 8px;
    }
  }
}

@mixin field-text-style {
  .field-text {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: calc(100% - 2px);
    transform: translateX(1px);

    .value {
      display: flex;
      align-items: center;
      overflow: hidden;
      background-color: transparent;
      white-space: nowrap;
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      max-height: 100%;
      padding: 5px 10px;
      border: none;
    }
  }
}

@mixin field-scroll-style {
  &::-webkit-scrollbar {
    $scroll-thickness: 14px;
    width: $scroll-thickness;
    height: $scroll-thickness;
  }
  &::-webkit-scrollbar-thumb {
    border: 3px solid $white;
    background-color: $silver-twelve;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $white;
  }
}

@mixin field-options-style {
  .field-options {
    @include field-scroll-style();

    background-color: $white !important;
    box-shadow: 0 0 10px 0 rgba($battleship-grey, 0.25) !important;

    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    max-height: $maxOptionsPanelHeight;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    pointer-events: none;
    transition: all 0.5s ease;
    z-index: 200;

    &.open {
      height: auto;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    &.top {
      bottom: 100%;
    }
    &.bottom {
      top: 100%;
    }
    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;
      @include themify() {
        color: themed('opt-list-el-txt');
      }
      &.active-option,
      &.selecting-option,
      &:hover,
      &.option-selected {
        &:not(.option-info) {
          @include themify() {
            background-color: themed('opt-list-el-bg-hover');
          }
        }
      }
      &.option-info {
        cursor: auto;
      }
      &.large {
        padding: 10px;
        font-size: 16px;
      }
      &.medium {
        padding: 8px;
        font-size: 14px;
      }
      &.small {
        padding: 6px;
        font-size: 12px;
      }
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      .checkbox-icon {
        min-width: 20px;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        font-size: 12px;
        border: 1px solid;
        border-radius: 2px;
        @include themify() {
          border-color: themed('checkbox-brdr');
        }
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: $bright-blue;
        }
      }
    }
  }
}

@mixin delete-cross-icon {
  &.delete-cross-icon {
    width: 24px;
    height: 24px;
    background-image: url('/assets/images/sprite.png');
    background-repeat: no-repeat;
    background-position: -148px -72px;
  }
}

// Datepicker style for different height elements
@each $key, $value in $heights {
  .#{$key} {
    .field-text {
      ngb-datepicker {
        top: $value !important;
        z-index: 100;
        background-color: aliceblue;
      }
    }
  }
}
