.mat-tooltip-panel {
  pointer-events: none;

  &:has(.mat-tooltip_interactable) {
    pointer-events: auto;
  }
}

.mat-tooltip {
  font-size: 12px;
  max-width: 275px !important;
  pointer-events: none;

  &_pre-line {
    white-space: pre-line;
    line-height: 18px;
  }
}

.full-text-tooltip {
  word-break: break-all;
  white-space: normal;
}
