@import "colors";

$site-themes: (
  'theme-coef': (light: 0, dark: 1),
  'header-bg': (
    // light: transparent linear-gradient(90deg, $blue-one 0%, $blue-two 100%) 0% 0% no-repeat padding-box,
    light: white,
    dark: transparent linear-gradient(90deg, $blue-one 0%, $blue-two 100%) 0% 0% no-repeat padding-box
  ),
  'header-btn': (light: $slate-six, dark: $pale-grey-three),
  'header-btn-effects': (light: $pale-grey-three, dark: $slate-six),
  'header-tgl-btn': (light: $white, dark: $white),
  'header-logo-url': (
    light: url('/assets/images/left-sidebar/header-logo-dark.png'),
    dark: url('/assets/images/left-sidebar/header-logo-white.png')
  ),
  'header-logo-url-small': (
    light: url('/assets/images/left-sidebar/header-logo-dark-small.png'),
    dark: url('/assets/images/left-sidebar/header-logo-white-small.png')
  ),

  'left-menu-bg': (light: $left-panel-light-bg, dark: $left-panel-dark-bg),
  'left-menu-bg-shadow': (
    light: 0px 0px 25px $light-shadow,
    dark: 0px 0px 25px #141C2B42
  ),
  'left-menu-hover': (light: $pale-grey-one, dark: $charcoal-grey-four),
  'left-menu-active': (
    light: transparent linear-gradient(90deg, $blue-one 0%, $blue-two 100%) 0% 0% no-repeat padding-box,
    dark: transparent linear-gradient(90deg, $blue-one 0%, $blue-two 100%) 0% 0% no-repeat padding-box
  ),
  'left-menu-txt': (light: $menu-item, dark: $menu-item),
  'left-menu-txt-hover': (light: white, dark: white),
  'left-menu-txt-active': (light: white, dark: white),

  'left-menu-icon-dashboard':                 (light:   0      0,   dark:  -72px    0),
  'left-menu-icon-visits':                    (light:   0    -24px, dark:  -72px  -24px),
  'left-menu-icon-users':                     (light:   0    -48px, dark:  -72px  -48px),
  'left-menu-icon-patients':                  (light:   0    -72px, dark:  -72px  -72px),
  'left-menu-icon-payers':                    (light:   0    -96px, dark:  -72px  -96px),
  'left-menu-icon-physicians':                (light:   0   -120px, dark:  -72px -120px),

  'left-menu-icon-dashboard-hover':           (light: -24px    0,   dark:  -96px    0),
  'left-menu-icon-visits-hover':              (light: -24px  -24px, dark:  -96px  -24px),
  'left-menu-icon-users-hover':               (light: -24px  -48px, dark:  -96px  -48px),
  'left-menu-icon-patients-hover':            (light: -24px  -72px, dark:  -96px  -72px),
  'left-menu-icon-payers-hover':              (light: -24px  -96px, dark:  -96px  -96px),
  'left-menu-icon-physicians-hover':          (light: -24px -120px, dark:  -96px -120px),

  'left-menu-icon-dashboard-active':          (light: -48px    0,   dark: -120px    0),
  'left-menu-icon-visits-active':             (light: -48px  -24px, dark: -120px  -24px),
  'left-menu-icon-users-active':              (light: -48px  -48px, dark: -120px  -48px),
  'left-menu-icon-patients-active':           (light: -48px  -72px, dark: -120px  -72px),
  'left-menu-icon-payers-active':             (light: -48px  -96px, dark: -120px  -96px),
  'left-menu-icon-physicians-active':         (light: -48px -120px, dark: -120px -120px),

  'body-bg': (light: $pale-grey-two, dark: $charcoal-grey),
  'root-pg-bg': (light: $pale-grey-one, dark: $night-four),
  'image-field-bg': (
    light: url('/assets/images/avatar.png'),
    dark: url('/assets/images/avatar1.png')
  ),
  'image-field-shadow': (
    light: 0 5px 15px $light-shadow,
    dark: 0 5px 15px #141C2B8F
  ),

  'v-tab-bg': (light: $white-six, dark: $slate-one),
  'v-tab-bg-active': (light: $white, dark: $dark-five),
  'v-tab-txt': (light: $rabbit-one, dark: $silver-eleven),
  'v-tab-txt-active': (light: $dark-five, dark: $white-six),
  'v-tab-txt-hover': (light: $dark-five, dark: $white-six),

  'v-content-shadow': (
    light: 0 5px 15px $light-shadow,
    dark: 0 5px 15px #141C2B42
  ),
  'v-content-bg': (light: $white, dark: $dark-five),
  'v-content-header-bg': (light: $white-six, dark: $beaver-two),
  'v-content-header-txt': (light: $dark-five, dark: $white-six),
  'v-content-top-border': (light: $pale-grey-one, dark: $slate-one),

  'v-step-bg': (light: $silver-six, dark: $beaver-two),
  'v-step-bg-hover': (light: $pale-grey-one, dark: $beaver-one),
  'v-step-txt': (light: $rabbit-one, dark: $silver-eleven),

  'ipanel-bg': (light: $white, dark: $beaver-two),
  'ipanel-bg-shadow': (
    light: 0px 5px 15px $light-shadow,
    dark: 0px 5px 15px #141C2B42
  ),
  'ipanel-head-txt': (light: $dark-five, dark: $white),
  'ipanel-content-txt': (light: $rabbit-one, dark: $silver-eleven),

  'txt1': (light: $slate-nine, dark: $white),
  'txt2': (light: $silver-nine, dark: $silver-nine),
  //'txt3': (light: $slate-nine, dark: $silver-nine),
  'lbl-txt1': (light: $silver-nine, dark: $silver-nine),

  'breadcrumbs-bg': (light: $silver-eleven, dark: $rabbit-three),
  'breadcrumbs-txt': (light: $silver-eleven, dark: $silver-eleven),
  'breadcrumbs-txt-clickable': (light: $silver-eleven, dark: $silver-eleven),
  'breadcrumbs-txt-active': (light: $dark-five, dark: $silver-eleven),

  // Every simple element
  'el-bg': (light: $row-bg-hover, dark: $beaver-two),
  'el-bg-active': (light: $silver-nine, dark: $night-three),
  'el-txt': (light: $rabbit-one, dark: $pale-grey-two),
  'el-txt-active': (light: $battleship-grey, dark: $pale-grey-two),

  'el-lbl-txt': (light: $dark-five, dark: $white-four),
  'el-lbl-active': (light: $battleship-grey, dark: $pale-grey-two),
  'el-lbl-txt-active': (light: $bright-blue, dark: $pale-grey-six),

  'el-brdr': (light: $inside-border, dark: $inside-border),
  'el-brdr-active': (light: $bright-blue, dark: $bright-blue),
  'el-brdr-error': (light: $hot-steal, dark: $hot-steal),
  'el-brdr-hover': (light: $slate-ten, dark: $night-three),

  'el-err-txt': (light: $hot-steal, dark: $hot-steal),

  // Options list
  'opt-list-bg': (light: $white, dark: $steel-one),
  'opt-list-txt': (light: $slate-six, dark: $pale-grey-six),
  'opt-list-el-txt': (light: $text-dark, dark: $night-two),
  'opt-list-el-bg-hover': (light: $row-bg-hover, dark: $night-two),

  // Checkbox
  'checkbox-hover': (light: $silver-ten, dark: $slate-eight),
  'checkbox-bg': (light: $row-border-grey, dark: $night-two),
  'checkbox-v2-bg': (light: $white-six, dark: $beaver-one),
  'checkbox-brdr': (light: $row-border-grey, dark: $night-one),
  'checkbox-txt': (light: $dark-five, dark: $pale-grey-two),

  // ngb-datepicker
  'ngb-dtpckr-bg': (light: $white, dark: $beaver-one),
  'ngb-dtpckr-border': (light: $silver, dark: $beaver-two),
  'ngb-dtpckr-shadow': (
    light: 0px 10px 15px $light-shadow,
    dark: 0px 10px 15px #141C2B42
  ),
  'ngb-dtpckr-txt': (light: $slate-nine, dark: $white-six),

  // Sized table
  'sized-table-shadow': (light: 0 0 30px $light-shadow, dark: 0 5px 15px #141C2B42),
  'sized-thead-bg': (light: $silver-twelve, dark: $dark-five),
  'sized-thead-txt': (light: $text-dark, dark: $pale-grey-two),
  'sized-row-odd-bg': (light: $white, dark: $dark-five),
  'sized-row-even-bg': (light: $white-eight, dark: $dark-five),
  'sized-row-txt': (light: $text-dark, dark: $pale-grey-two),
  // 'sized-row-bg-1': (light: $white-six, dark: $slate-one),
  // 'sized-row-bg-2': (light: $white-seven, dark: $slate-eleven),
  'sized-row-bg-hover': (light: $row-bg-hover, dark: $dark-six),

  // Paginator
  'paginator-bg': (light: $white, dark: $beaver-two),
  'paginator-bg-disabled': (light: $white-six, dark: $slate-eleven),
  'paginator-bg-active': (light: $row-bg-hover, dark: $row-bg-hover),
  'paginator-border': (light: $silver-eleven, dark: #8294D833),
  'paginator-border-active': (light: $blue-three, dark: $blue-three),
  'paginator-border-hover': (light: $blue-three, dark: $blue-three),
  'paginator-txt': (light: $dark-five, dark: $white-six),
  'paginator-txt-disabled': (light: $silver-eleven, dark: $dark-five),
  'paginator-txt-active': (light: $row-text-grey, dark: $row-text-grey),
  'paginator-txt-hover': (light: $blue-three, dark: $blue-three),

  // Simple button
  'simple-btn-txt': (light: $white, dark: $white),

  'btn-shadow-red': (light: 0 5px 10px 0 $mid-red, dark: 0 5px 10px 0 $mid-red-03),
  'btn-shadow-green': (light: 0 5px 10px 0 $boring-green, dark: 0 5px 10px 0 $boring-green-03),
  'btn-shadow-blue': (light: 0 5px 10px 0 $button-blue, dark: 0 5px 10px 0 $button-blue-03),

  // Sticky buttons
  'stck-btns': (light: $white-five, dark: $darkness-step-two),
  'stck-btns-shadow': (light: 0px -5px 25px $cool-grey-33, dark: 0px -5px 25px $charcoal-grey-seven-33),

  'box-shadow-one': (
    light: 0 0 10px 0 rgba($battleship-grey, 0.1),
    dark: 0 0 10px 0 rgba($dark-three, 0.2)
  ),
  'box-shadow-two': (
    light: 0 0 10px 0 rgba($battleship-grey, 0.25),
    dark: 0 0 10px 0 rgba($dark-three, 0.25)
  ),

  // Navigation
  'nav-tab-bg': (light: $white, dark: $darkness-step-two),
  'nav-tab-active': (light: $green-one, dark: $green-one),

  'test': (light: $lipstick, dark: $bright-blue),
);

$control-standard-height: 44px;
$heights: (small: 26px, medium: 38px, large: $control-standard-height);
$steps: ('0': 0, '5': 5px, '10': 10px, '15': 15px, '20': 20px, '25': 25px, '30': 30px);
$general-font-size: 14px;
$secondary-font-size: 12px;


// Custom fonts
$base-font: 14px 'Light Poppins', Arial, sans-serif;
$secondary-font: 14px 'Regular Poppins', Arial, sans-serif;
$secondary-font-bold: 14px 'Medium Poppins', Arial, sans-serif;
$title-font: 16px 'Medium Poppins', Arial, sans-serif;
$subTitle-font: 13px 'Light Poppins', Arial, sans-serif;
$bold-font: 'Bold Poppins', Arial, sans-serif;

// Inter fonts
$table-font: Inter, sans-serif;
$inter-semi-bold: 600 12px 'Inter', sans-serif;
$inter-regular: 400 12px 'Inter', sans-serif;

$app-header-height: 65px;
$app-top-panel-height: 54px;
