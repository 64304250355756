::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 4px;
    background: $white;
}

::-webkit-scrollbar-thumb {
    background: $pale-grey-scroll;
    border-radius: 4px;
}