.btn {
  font-family: 'SF UI Display', sans-serif;
  font-size: 12px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 36px;
  padding: 5px;
  border-radius: 2px;
  border: none;
  outline: none !important;
  transition: all 0.4s ease;
  cursor: pointer;

  &.disabled,
  &:disabled {
    pointer-events: none;
  }

  &.btn-small {
    height: 26px;
  }

  &.btn-outline,
  &.btn-outline-gray {
    border: 1px solid;
  }

  &.btn-default-shadow {
    &:after {
      content: '';
      margin: 0 15px;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 10px;
      position: absolute;
      background-color: transparent;
      transition: all 0.4s ease;
      border-radius: 25%;
    }
  }

  &.btn-badge {
    height: 22px;
    width: auto;
    min-width: 22px;
    padding: 0 4px;
    border-radius: 2px;
    &.only-icon {
      i {
        margin-right: 0;
      }
    }

    i {
      margin-right: 4px;
    }
  }

  i {
    margin-right: 10px;
  }
}
