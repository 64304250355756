@import "full-callendar";
@import "wizard";
@import "group";
@import "popup";
@import "page";
@import "google-map";
@import "ng-select";

.table-search {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 25px;
  font-size: 12px;
  flex-wrap: wrap;

  &__item-label {
    font-size: 13px;
    margin-left: 5px;
  }

  &__item {
    width: 180px;
    height: 32px !important;

    .element-label, .field-label {
      background-color: $silver-twelve !important;
    }
  }
  &__search {
    max-width: 450px;
    height: 32px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: $primary-button-blue;
  }

  .app-date-range, app-datepicker-field {
    .mat-form-field {
       height: 32px;
      .mat-form-field-flex {
        height: 30px;
        background-color: $silver-twelve !important;
        .mat-date-range-input-container input {
          font-size: 12px;
        }

        input::placeholder, .mat-date-range-input-separator {
          color: $rabbit-one !important;
        }
      }
    }
  }

  app-multiselect-field {
    min-width: 140px;

    mat-select {
      background-color: $silver-twelve !important;

      &.fake-value {
        .mat-select-placeholder {
          color: $text-dark !important;
        }
      }

      .mat-select-placeholder {
        color: $rabbit-one !important;
      }

      .selected-text {
        color: $text-dark !important;
      }
    }
  }
}

.visit {
  color: $white;
  padding-left: 4px !important;
  &-0 {
    background-color: #FFC300; // available
  }
  &-1 {
    background-color: #E53935; // canceled
  }
  &-2 {
    background-color: #FFC300; // offered
  }
  &-3 {
    background-color: #00BCD4; // requested
  }
  &-4 {
    background-color: #1E88E5; // scheduled
  }
  &-5 {
    background-color: #D81B60; // missed
  }
  &-6 {
    background-color: #00E676; // enRoute
  }
  &-7 {
    background-color: #E53935; // noShow
  }
  &-8 {
    background-color: #43A047; // inProgress
  }
  &-9 {
    background-color: #9E9E9E; // completed
    color: $black;
  }
}
