@import "variables";

#toast-container {
  z-index: 1000;
  &.toast-top-left, &.toast-top-center, &.toast-top-right { top: 6px; }
  &.toast-top-right { right: 6px; }
  &.toast-top-left { left: 6px; }

  .ngx-toastr {
    border: none;

    &.toast-success {
      background-color: $greenblue;
      box-shadow: 0 5px 15px 0 rgba(32, 201, 151, 0.4);
      &:hover {
        box-shadow: 0 5px 15px 0 rgba(32, 201, 151, 0.4);
      }
    }
    &.toast-error {
      background-color: $hot-steal !important;
      box-shadow: 0 5px 15px 0 rgba(244, 81, 108, 0.4);

    }
    &.toast-warning {
      color: $black;
      background-color: $orange-ligth;
      box-shadow: 0 5px 15px 0 rgba(255, 210, 133, 0.4);
    }

    .toast-message {
      white-space: pre-wrap;
    }
  }
}
