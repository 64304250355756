@import "../mixins/main";
@import "../variables";

.mat-table {
  color: $text-dark;
  font-family: $table-font;
  margin-bottom: 0;

  & > .mat-header-row {
    min-height: 44px;
    align-items: flex-start;
    background-color: $silver-twelve;
  }

  & > .mat-row {
    transition: all .2s ease;
    min-height: 44px;
    border-bottom: 1px solid $table-border;
    border-left: 1px solid $table-border;
    border-right: 1px solid $table-border;
    background-color: $white;

    &:not(.expanded-row):hover {
      background-color: $table-hover !important;
    }

    &:nth-child(even) {
      background-color: $white-eight;
    }
  }

  .mat-header-cell {
    min-height: 44px;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 2px 10px;
    color: $text-dark;
  }

  .mat-cell {
    color: $text-dark;
    padding: 2px 10px;
    font-size: $secondary-font-size;
    word-break: break-word;

    a {
      text-decoration: underline;
      color: $dark-blue;
    }
  }

  &.table-expandable {
    & > .mat-row {
      cursor: pointer;

      &:nth-child(even) {
        background-color: $white;
      }

      &:nth-child(4n) {
        background-color: $white-eight;
      }

      &:nth-child(4n + 1) {
        background-color: $white-eight;
      }

      &.expanded-row {
        min-height: auto;
        border-bottom: none;
      }

      &.btn-expanded-row {
        border-bottom: none;
      }
    }

    .mat-cell {
      padding-top: 0;
      padding-bottom: 0;

      &.mat-column-expandedDetail {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;

        .expanded-container {
          width: 100%;
          display: flex;
          flex-direction: column;

          &.expanded {
            padding: 10px;
          }

          & > * {
            box-shadow: 0 4px 16px $light-shadow4;
          }
        }
      }
    }
  }
}
