@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$enable-shadows: true;
$enable-gradients: true;
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/card";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";

@import 'ngx-toastr/toastr.css';
@import 'ngx-toastr/toastr-bs4-alert';

// Custom styles
@import 'assets/styles/toastr';
@import 'assets/styles/buttons';
@import 'assets/styles/label';
@import 'assets/styles/scroll';
// @import 'assets/styles/multi-themes';
@import 'assets/styles/fonts';
@import 'assets/styles/regular';
@import 'assets/styles/fontawesome';
// @import 'assets/styles/themes';
@import 'assets/styles/notification-budget';
@import "styles/colors";
@import "styles/utils";
@import "styles/mixins/icon-colors";
@import "assets/styles/material";
@import "styles/components/components";

@import "styles/wizard-editor-content";

//-------------Global styles--------------
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font: $base-font;
}

ul, li {
  list-style: none;
}

:focus {
  outline: none !important;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
  }
}

label {
  margin: 0;

  &.required:after {
    content: '*';
  }
}

button {
  border: none;

  &:focus {
    outline: none;
  }
}

// remove default 'eye' icon for inputs with the type 'password' in some browsers
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

textarea {
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: $header-grey !important;
}
.wf-loading body {
  display: none;
}

// remove arrows for input type='number'
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-button {
  height: 38px;
  padding: 0 16px;
  transition: all .3s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &_blue {
    color: $primary-button-blue;
    background-color: $avatar;
    &:hover, &:focus {
      color: $white;
      background-color: $primary-button-blue;
    }
  }
  &_red {
    color: $hot-warning;
    background-color: $hot-warning-hover;
    &:hover, &:focus {
      color: $white;
      background-color: $hot-warning;
    }
  }
  &_gray {
    color: $header-grey;
    background-color: $row-border-grey;
    &:hover, &:focus {
      color: $primary-button-blue;
    }
  }
  &_green {
    color: $green-text;
    background-color: $green-avatar;
    &:hover, &:focus {
      color: $white;
      background-color: $green-text;
    }
  }
  &_brown {
    color: $brown;
    background-color: $brown-light;
    &:hover, &:focus {
      color: $white;
      background-color: $brown;
    }
  }
  &_spinner {
    padding: 2px 25px;
    position: relative;
    gap: 4px;

    .spinner {
      position: absolute;
      left: 6px;
    }
  }
  &:disabled {
    color: $disable-button-text;
    background-color: $disable-button;
    cursor: not-allowed;
  }
}

.form-control {
  background-image: none !important;
  height: 44px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid $row-bg-hover;
  background-color: $row-bg-hover;
  color: $row-text-grey !important;
  box-shadow: none !important;
  padding-left: 9px;
  &:disabled {
    color: $header-grey !important;
  }
  &:focus {
    border: 1px solid $focused-blue;
  }
  &:hover:not([disabled]) {
    border: 1px solid $slate-ten;
  }
  &[readonly]:not(.readonly-view) {
    border-color: transparent !important;
    color: $header-grey !important;
  }
  &.is-invalid {
    border-color: $hot-steal !important;
  }
}

.avatar-label {
  box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.3) !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 -20px 140px;
  background: white;
  position: inherit;

  i {
    margin-right: 0;
  }
}

.top-panel {
  .breadcrumbs {
    display: flex;
    align-items: center;

    .action {
      font-size: 13px;
      color: $action-text;
      display: flex;
      align-items: center;

      .separator {
        margin: 0 16px;
        height: 20px;
        width: 1px;
        background: $action-text;
      }
    }
  }
}

.top-panel > .button, .modal__button {
  height: 36px;
  padding: 0 25px;
  color: #1BC5BD;
  background-color: #C9F7F5;
  transition: 0.2s;
  border-radius: 6px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: white;
    background-color: #1BC5BD;
  }

  &:disabled {
    color: $disable-button-text;
    background-color: $disable-button;
  }
}
.no-found {
  border-top: 1px solid $row-border-grey;
  border-bottom: 1px solid $row-border-grey;
  padding: 14px 0;
  font-family: $table-font;
  font-size: 12px;
  text-align: center;
}
.icon-table-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background: none;
}
.visit-status-color {
  &-0 {
    color: $status-available;
  }
  &-1 {
    color: $status-canceled;
  }
  &-2 {
    color: $status-offered;
  }
  &-3 {
    color: $status-requested;
  }
  &-4 {
    color: $status-scheduled;
  }
  &-5 {
    color: $status-missed;
  }
  &-6 {
    color: $status-en-route;
  }
  &-7 {
    color: $status-no-show;
  }
  &-8 {
    color: $status-in-progress;
  }
  &-9 {
    color: $status-completed;
  }
}
.visit-status-bg {
  &-0 {
    background-color: $status-available;
  }
  &-1 {
    background-color: $status-canceled;
  }
  &-2 {
    background-color: $status-offered;
  }
  &-3 {
    background-color: $status-requested;
  }
  &-4 {
    background-color: $status-scheduled;
  }
  &-5 {
    background-color: $status-missed;
  }
  &-6 {
    background-color: $status-en-route;
  }
  &-7 {
    background-color: $status-no-show;
  }
  &-8 {
    background-color: $status-in-progress;
  }
  &-9 {
    background-color: $status-completed;
  }
}

.billing-status-bg {
  &-11 {
    background-color: $orange;
  }

  &-16 {
    background-color: $green-text;
  }

  &-18 {
    background-color: $status-scheduled;
  }
}

.status-badge {
  padding: 2px 10px;
  border-radius: 6px;

  &_green {
    background-color: $green-avatar;
    color: $green-text;
  }

  &_red {
    background-color: $mid-red-01;
    color: $hot-warning;
  }

  &_gray {
    background-color: $pale-grey-five;
    color: $pale-grey-text;
  }
}

.batch-status-badge {
  width: auto;
  padding: 3px 15px;
  border-radius: 4px;
  color: $white;
  text-align: center;
}

.create-button {
  height: 36px;
  border: 0;
  padding: 0 12px;
  color: $primary-button-blue;
  background-color: $avatar;
  transition: all 0.3s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $primary-button-blue;
    color: white;
  }

  &:disabled {
    color: $disable-button-text;
    background-color: $disable-button;
  }
}

.button--delete {
  height: 36px;
  width: 120px;
  border: 0;
  color: $hot-warning;
  background-color: $hot-warning-hover;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $hot-warning;
    color: white;
  }

  &:disabled {
    color: $disable-button-text;
    background-color: $disable-button;
  }
}

// custom mat-button
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font: $base-font;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  &:disabled {
    cursor: not-allowed !important;
  }
}

.mat-divider {
  border-top-color: $row-border-grey;
}

// custom mat-error
.mat-error {
  color: $hot-steal;
  font-size: 12px;
}

// custom mat-dialog
mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-content {
  max-height: calc(100vh - 130px) !important;
  margin: 0 !important;
  padding: 0 !important;
  background-color: $white;
}

// custom mat-select
.fields-input {
  &__action {
    display: flex;
    align-items: center;

    app-fake-select-field {
      width: 50%;
    }
  }

  &__label {
    width: 25%;
    padding-right: 30px;
    text-align: end;
  }

  &__timepicker {
    background-color: $row-bg-hover;
    border-radius: 6px;
    height: 42px;
    border: 1px solid $row-bg-hover;
    padding-right: 3px;

    &.has-error {
      border: 1px solid $hot-steal;
    }

    &.view-mode {
      pointer-events: none;
    }

    .ngx-timepicker {
      border: none !important;
    }

    .ngx-timepicker-control__input {
      background-color: $row-bg-hover;
    }

    .period-control__button,
    .ngx-timepicker-control--active {
      &:after {
        background-color: $primary-button-blue !important;
      }
    }

    .period-control__button:after {
      bottom: -9px !important;
    }

    .ngx-timepicker-control--active:after {
      bottom: -6px !important;
    }
  }

  textarea[readonly] {
    border-color: $row-bg-hover !important;
  }
}
.app-select-overlay {
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: $silver-ten;
  }
  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background-color: $silver-ten;
  }
  .mat-option.mat-active {
    background-color: $silver-ten;
    color: inherit;
  }
}

.field-textarea {
  .fields-input__action {
    align-items: baseline;
  }
}

// custom mat-date-range-input
.app-date-range {
  .mat-form-field {
    width: 100%;
    font-size: 14px;
    font-family: 'Light Poppins', sans-serif;
    height: 38px;
    border: 1px solid $row-bg-hover;
    border-radius: 4px;
    transition: border .3s ease;
    &:hover {
      border-color: $slate-ten;
    }
    &.mat-focused {
      border-color: $bright-blue;
    }
    &-flex input {
      color: $row-text-grey !important;
    }
  }
  .mat-form-field-wrapper {
    padding: 0;
    .mat-button-wrapper {
      transform: translateY(1px);
    }
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 6px 0 10px;
    align-items: center;
    background-color: $row-bg-hover;
    height: 36px;
    border-radius: 4px;
    padding-top: 2px;
  }
  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0;
    width: 195px;
    height: 20px;
  }
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: -2px;
  }
  .mat-form-field-underline,
  .mat-button-focus-overlay {
    display: none;
  }
  .mat-form-field-infix {
    border: none;
  }
  .mat-button-focus-overlay {
    display: none;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 38px;
  }
  .picker-field {
    mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 2px;
      top: 2px;
      cursor: pointer;
    }
  }
  .mat-date-range-input-start-wrapper,
  .mat-date-range-input-end-wrapper {
    min-width: 77px;
  }
  .mat-date-range-input-start-wrapper {
    text-align: right;
  }
  .mat-date-range-input {
    height: 100%;
    .mat-date-range-input-container {
      height: 100%;
      .mat-date-range-input-start-wrapper,
      .mat-date-range-input-end-wrapper {
        height: 100%;
        > * {
          height: 100%;
        }
      }
    }
    .mat-date-range-input-separator {
      transform: translateY(1px);
      margin: 0 5px;
      color: $header-grey;
    }
    &.mat-date-range-input-hide-placeholders {
      .mat-date-range-input-separator {
        color: inherit;
      }
    }
  }
  &.readonly {
    cursor: not-allowed;
    .mat-form-field {
      pointer-events: none;
      .mat-icon {
        display: none;
      }
    }
  }
}
.app-date-field {
  .mat-form-field {
    border-radius: 6px;
    border: 1px solid $row-bg-hover;
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    font: inherit;
    &:hover {
      border-color: $slate-ten;
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-flex {
    border-radius: 6px;
    height: 41px;
    background-color: $row-bg-hover;
    padding-top: 0 !important;
    padding-left: 9px;
    display: flex;
    align-items: center;
  }
  .mat-form-field-flex input {
    font-family: 'Light Poppins', sans-serif;
    font-size: 14px;
    color: $row-text-grey;
    caret-color: $row-text-grey;
    height: 20px;
  }
  .mat-form-field-infix {
    width: auto;
    min-width: 90px;
    padding: 0;
    border: 0;
  }
  .mat-form-field-underline {
    display: none !important;
  }
}

// custom mat-chip
.app-chip-select {
  width: 100%;
  font-family: 'Light Poppins', sans-serif;
  line-height: normal;

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
      transform: none;
      width: initial;
      opacity: 0;
    }

    .mat-form-field-outline-gap {
      border-top-color: initial;
    }
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .mat-form-field-label {
    top: 8px;

    &-wrapper {
      top: 0;
      padding: 0;
    }
  }

  .mat-form-field-infix {
    padding: 7px 0;
  }

  .mat-chip-list-wrapper {
    .mat-chip-input {
      margin: 9px;
    }
  }

  .mat-chip-remove {
    .mat-icon {
      height: 18px;
      width: 18px;
      font-size: 20px;
      transform: translateY(calc(50% - 10px));
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-color: $row-bg-hover !important;
      background-color: $row-bg-hover !important;
    }

    .mat-form-field-wrapper {
      margin-bottom: 0 !important;
    }

    &.isError {
      .mat-form-field-outline {
        border: 1px solid red;
        border-radius: 6px;
      }

      .mat-form-field-outline-thick {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border: none;
        }
      }
    }
  }
}

// custom mat-slider
.mat-slide-toggle {
  width: 36px !important;
  height: 20px !important;
  line-height: 20px;
  .mat-slide-toggle-ripple {
    display: none !important;
  }
}
.mat-slide-toggle-content {
  display: none;
}
.mat-slide-toggle-bar {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
}
.mat-slide-toggle-label {
  height: 100% !important;
}
.mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $button-bg-action;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $primary-button-blue;
}
.mat-slide-toggle-thumb-container,
.mat-slide-toggle-thumb {
  height: 16px !important;
  width: 16px !important;
}
.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 2px !important;
}
.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}
.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: $white;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $white;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(10px, 0, 0);
}


.app-mat-autocomplete {
  max-height: 160px !important;

  .mat-option {
    line-height: normal;
    height: auto;
    padding: 9px 16px;
    transition: all .3s ease;
    font: $base-font;

    &:hover {
      background-color: $silver-ten;
    }
  }
}

// custom mat-spinner
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary-button-blue;
}

// resize map on DispatchScreen page
.dispatch {
  .resize {
    display: block;
    width: calc(100% - 65px);
    height: calc(100% - 12px) !important;
    position: absolute;
    &.show-table {
      width: calc(50% - 65px);
    }
    .border {
      position: absolute;
      background: none;
      border: none !important;
    }
    .border-top,
    .border-bottom {
      left: 0;
      right: 0;
      height: 6px;
      cursor: row-resize;
    }
    .border-left,
    .border-right {
      top: 0;
      bottom: 0;
      width: 6px;
      cursor: col-resize;
    }
    .border-left {
      left: 0;
    }
    .border-right {
      right: 0;
    }
    .border-top {
      top: 0;
    }
    .border-bottom {
      bottom: 0;
    }
    .border-top,
    .border-left {
      cursor: default;
    }
  }
}

// table search arrow
.icon-sort {
  width: 16px !important;
  height: 12px !important;
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
  transition: all .2s ease;
  transform: translateY(-1px);
}
.header-cell-name {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-cell-name, .sort-cell {
  &.sortAsc {
    .icon-sort {
      transform: translateY(-1px);
      opacity: 1;
    }
  }
  &.sortDesc {
    .icon-sort {
      transform: rotate(180deg) translateY(1px);
      opacity: 1;
    }
  }
  &.noSort {
    .icon-sort {
      opacity: .35;
    }
    &:hover {
      .icon-sort {
        opacity: .65;
      }
    }
  }
}

// custom mat multiple select
.app-multiple-select {
  .mat-form-field {
    width: 100%;
    .mat-select.mat-select-invalid {
      border: 1px solid $hot-steal;
    }
  }
  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-label-wrapper {
    display: none;
  }
  .mat-form-field-infix {
    border: none;
  }
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    border: none;
    padding: 0;
    border-radius: 6px;
    background-color: $row-bg-hover;
  }
  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: none;
  }
  .mat-select {
    font-family: 'Light Poppins', sans-serif;
    font-size: 14px;
    padding: 14px 10px;
    height: 42px;
    border: 1px solid transparent;
    border-radius: 6px;
  }
}
.app-multiple-select-panel {
  min-width: calc(100% + 18px) !important;
  max-height: 190px !important;
  .mat-pseudo-checkbox-checked {
    background-color: $white;
  }
  .mat-option {
    font-family: 'Light Poppins', Arial, sans-serif;
    font-size: 16px !important;
    line-height: normal !important;
    height: auto !important;
    padding: 10px;
    transition: all .3s ease;
    border-bottom: 1px solid $white;
    &:hover {
      background: $silver-ten;
    }
    &.mat-selected {
      background: $silver-ten;
      &:not(.mat-option-disabled) {
        color: inherit;
      }
    }
  }
  .mat-pseudo-checkbox-checked::after {
    top: 4px;
    left: 3px;
    width: 9px;
    height: 4px;
    border-left: 3px solid $primary-button-blue;
  }
  .mat-pseudo-checkbox::after {
    border-bottom: 3px solid $primary-button-blue;
  }
  .mat-pseudo-checkbox {
    width: 20px;
    height: 20px;
    border-color: $row-border-grey;
    border-radius: 2px;
  }
}

.app-timepicker-select-panel {
  min-width: 100% !important;
  transform: scaleY(1) translateX(10px) !important;
  &::-webkit-scrollbar {
    width: 0;
  }
  .mat-option-text {
    text-align: center;
  }
}


.loader {
  color: $white;
  font-size: 3px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


.close-icon {
  font-size: 18px;
  cursor: pointer;
}

.field-options {
  .option-selected {
    background-color: $silver-ten;
  }
}
.underline-text {
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $row-text-grey;
    position: absolute;
    left: 0;
    bottom: 2px;
  }
}
// visit details popup
.visit-details {
  section {
    padding: 8px 0;
    border: 1px solid $button-bg-action;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  .section-title {
    padding: 0 16px;
  }
  .form {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 32px;
      padding: 4px 30px 4px 16px;
    }
    &_label {
      color: $row-text-grey;
    }
    &__value {
      color: $battleship-grey;
    }
  }
  .editable-field {
    margin-right: -25px;
  }
  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    .app-button {
      padding: 0 30px;
      position: relative;
      .spinner {
        position: absolute;
        left: 10px;
      }
    }
  }
  .loading {
    margin: 50px 0;
  }
}

.action-button {
  height: 36px;
  width: 36px;
  border: 0;
  background-color: $button-bg-action;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: all .2s ease;

  &:not([disabled]):hover {
    filter: brightness(95%);
  }
}

.material-icons-two-tone {
  &.icon-dark-blue {
    @include icon-dark-blue;
  }
  &.icon-blue {
    @include icon-blue;
  }
  &.icon-green {
    @include icon-green;
  }
  &.icon-brown {
    @include icon-brown;
  }
  &.icon-gray {
    @include icon-gray;
  }
  &.icon-red {
    @include icon-red;
  }
  &.icon-orange {
    @include icon-orange;
  }
}

.action-panel {
  align-items: center;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  &__link {
    color: $primary-button-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  &__spinner {
    margin-right: 10px;
  }

  &.selecting {
    pointer-events: none;

    .action-panel__link,
    .app-button {
      opacity: .5;
    }
  }
}
.popup .mode__hours > span {
  color: $primary-button-blue;
  font-weight: bold;
}


@media all and (max-width: 768px) {
  .fields-input {
    &__action {
      flex-direction: column;

      app-fake-select-field {
        width: 100%;
      }
    }

    &__label {
      width: 100%;
      padding: 0;
      text-align: start;
      margin-bottom: 8px;
    }

    .popup__errors {
      padding: 0;
    }
  }
}

.control {
  &__date {
    &--short {
      max-width: 160px;
    }
  }
}

.dropdown-dialog-panel {
  position: absolute !important;
  top: 64px;
  right: 35px;
  width: 100%;
  .mat-dialog-container {
    box-shadow: 0 0 30px $light-shadow3;
    border-radius: 10px;
  }
}

.dropdown-dialog-backdrop {
  + .cdk-global-overlay-wrapper {

  }
}

.critical-notifications-dialog {
  right: 200px;
}

.full-screen-modal {
  max-width: 100vw !important;
}

