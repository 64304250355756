@import 'src/app/shared/fields/fields';

.wizard-section {
  &__header {
    height: 70px;
    padding: 0 36px;
    border-bottom: 1px solid $row-border-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    font-family: 'Medium Poppins', sans-serif;
  }

  &__content {
    padding: 36px;
  }

  &__row {
    display: flex;
    margin-bottom: 12px;
  }

  &__label {
    width: 100%;
    max-width: 200px;
    height: 44px;
  }

  &__input {
    width: 100%;
    max-width: 450px;
    overflow-x: clip;
  }
}

@media all and (max-width: 900px) {
  .wizard-section {
    &__row {
      flex-direction: column;
    }

    &__label {
      max-width: 100%;
    }

    &__input {
      max-width: 100%;
    }

    &__footer {
      display: flex;
      justify-content: right;
      margin-top: 20px;
    }
  }
}
