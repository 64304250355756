@import "styles/colors";

.fc {
  &-event {
    box-shadow: none !important;
    border: none !important;
  }

  &.fc-custom-theme {
    .fc-button-primary {
      color: $header-grey !important;
      background: transparent !important;
      border: 1px solid $row-border-grey !important;
      transition: all 0.2s ease;
      box-shadow: none !important;

      &:hover {
        background: $row-bg-hover !important;
      }

      &.fc-button-active {
        background: $primary-button-blue !important;
        color: $white !important;
        border-color: $primary-button-blue !important;
      }
    }

    .fc-list-event:hover td {
      background-color: $white !important;
    }

    .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
      margin-left: 0 !important;
    }

    .fc-button:not(:last-child) {
      border-right: none !important;
    }

    .fc-toolbar-title {
      font-weight: 500 !important;
      font-size: 18px !important;
      text-transform: uppercase !important;
    }

    .fc-list-day-text {
      color: $row-text-grey;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .9rem;
      text-decoration: none !important;

      &:hover {
        color: inherit;
        cursor: pointer;
      }
    }

    .fc-list-day-side-text {
      color: $header-grey !important;
      cursor: pointer;
      text-decoration: none !important;
    }

    .fc-scrollgrid-sync-inner {
      padding: 6px 0;
    }

    .fc-col-header-cell-cushion {
      color: $header-grey !important;
    }

    .fc-daygrid-day-number {
      color: $row-text-grey !important;
    }

    .fc-day-past {
      .fc-highlight {
        background: none !important;
      }
    }
  }
}

