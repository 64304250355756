@font-face {
  font-family: 'SF UI Display';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/sanfrancisco/SFUIDisplay-Regular.eot');
  src: local('SF UI Display Regular'), local('SFUIDisplay-Regular'),
  url('../fonts/sanfrancisco/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/sanfrancisco/SFUIDisplay-Regular.woff') format('woff'),
  url('../fonts/sanfrancisco/SFUIDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/sanfrancisco/SFUIDisplay-Medium.eot');
  src: local('SF UI Display Medium'), local('SFUIDisplay-Medium'),
  url('../fonts/sanfrancisco/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/sanfrancisco/SFUIDisplay-Medium.woff') format('woff'),
  url('../fonts/sanfrancisco/SFUIDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/sanfrancisco/SFUIDisplay-Semibold.eot');
  src: local('SF UI Display Semibold'), local('SFUIDisplay-Semibold'),
  url('../fonts/sanfrancisco/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/sanfrancisco/SFUIDisplay-Semibold.woff') format('woff'),
  url('../fonts/sanfrancisco/SFUIDisplay-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'FA Solid';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/fontawesome/fa-solid-900.eot');
  src: url('../fonts/fontawesome/fa-solid-900.eot?#iefix') format("embedded-opentype"),
  url('../fonts/fontawesome/fa-solid-900.woff2') format("woff2"),
  url('../fonts/fontawesome/fa-solid-900.woff') format("woff"),
  url('../fonts/fontawesome/fa-solid-900.ttf') format("truetype"),
  url('../fonts/fontawesome/fa-solid-900.svg#fontawesome') format("svg");
}

@font-face {
  font-family: 'FA Brands';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/fontawesome/fa-brands-400.eot');
  src: url('../fonts/fontawesome/fa-brands-400.eot?#iefix') format("embedded-opentype"),
  url('../fonts/fontawesome/fa-brands-400.woff2') format("woff2"),
  url('../fonts/fontawesome/fa-brands-400.woff') format("woff"),
  url('../fonts/fontawesome/fa-brands-400.ttf') format("truetype"),
  url('../fonts/fontawesome/fa-brands-400.svg#fontawesome') format("svg");
}

@font-face {
  font-family: 'Light Poppins';
  src: url('../fonts/poppins/Poppins-Light.ttf') format("truetype");
}

@font-face {
  font-family: 'Regular Poppins';
  src: url('../fonts/poppins/Poppins-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Medium Poppins';
  src: url('../fonts/poppins/Poppins-Medium.ttf') format("truetype");
}

@font-face {
  font-family: 'SemiBold Poppins';
  src: url('../fonts/poppins/Poppins-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Bold Poppins';
  src: url('../fonts/poppins/Poppins-Bold.ttf') format("truetype");
}

.fab {
  font-family: 'FA Brands';
}

.fa, .fas {
  font-family: 'FA Solid';
  font-weight: 900;
}
