@import "styles/colors";

.wizard {
  &-section {
    &__header {
      height: 50px;
      padding: 0 0 12px 0;
      border-bottom: 1px solid $row-border-grey;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    &__title {
      margin: 0;
      font-size: 16px;
      font-family: 'Medium Poppins', sans-serif;
    }

    &__table {
      padding: 12px;
    }

    &__content {
      padding: 0;
      min-height: calc(100vh - (#{$app-header-height} + #{$app-top-panel-height} + 145px));
      overflow-y: auto;
      overflow-x: hidden;
      @include field-scroll-style();

      .group {
        &:not(:last-child) {
          margin-bottom: 32px;
        }

        &__header {
          font-size: 16px;
          font-family: 'Medium Poppins', sans-serif;
        }
      }


      ::ng-deep .mat-focused {
        border: 1px solid #117cef !important;
      }

      ::ng-deep .mat-form-field {
        border-radius: 6px !important;
        border: 1px solid $row-bg-hover;
        box-sizing: border-box;
        height: 44px;
        width: 100%;

        &:hover {
          border: 1px solid $slate-ten;
        }

        &.is-invalid {
          border: 1px solid $hot-steal;
        }

        &-wrapper {
          padding-bottom: 0;
        }

        &-flex {
          border-radius: 6px !important;
          height: 41px;
          background-color: $row-bg-hover !important;
          padding-top: 0 !important;
          padding-left: 9px !important;

          display: flex !important;
          align-items: center !important;

          input {
            font-family: 'Light Poppins', sans-serif;
            font-size: 14px;
            color: $row-text-grey !important;
            caret-color: $row-text-grey !important;
            height: 20px;
          }
        }

        &-underline {
          display: none !important;
        }
      }

      ::ng-deep .mat-datepicker-content {
        font-family: 'Light Poppins', sans-serif !important;
      }

      .change-password__button {
        font-family: 'Regular Poppins', sans-serif;
        font-size: 14px;
        background-color: $avatar;
        color: $primary-button-blue;
        height: 38px;
        width: 183px;
        transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 6px;
        border: 0;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      label {
        align-items: center;
        display: flex;
      }
    }
  }
}

::ng-deep mat-dialog-container {
  padding: 0 !important;
}
